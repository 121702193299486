<template>
  <div
    class="training px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Personnel Training" size="large" />
    <img src="@/assets/homeslide4-min.jpg" />
    <div class="flex flex-wrap mt-8">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <TitleComponent title="Training" />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Our staff is knowledgeable and experienced in a wide range of safety
            related topics and has conducted training seminars/ workshops in
            many aspects of process safety management. Courses are tailored to
            meet the specific needs of the client company and its employees. The
            following courses are offered at the clients’ location or annually
            in Salt Lake City, Utah:
          </p>
          <ul>
            <li>
              <router-link
                class="link"
                to="/training/process-safety-management-psm-principles-explosive-operations"
                >Process Safety Management (PSM) Training and Principles for
                Explosives Operations, including an overview of the OSHA 29 CFR
                1910.119 and EPA 40 CFR Part 68 regulations and their elements,
                similarities, and differences</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/dot-atf-process-classification-testing"
                >DOT / DoD, ATF, and In-Process Classification
                Testing</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/process-hazards-analysis-pha-training-for-team-leaders"
                >Process Hazards Analysis (PHA) Training for Team Leaders,
                including a methodology overview and how techniques such as
                what-if check lists, HAZOP, FMEA, and fault tree logic diagrams
                can be effectively used</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/explosives-recognition-and-decontamination"
                >Explosives Recognition and Decontamination</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/fundamentals-of-explosives-safety"
                >Fundamentals of Explosives Safety</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/fundamentals-of-explosives-safety"
                >Fundamentals of Explosives Testing</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/fundamentals-of-a-combustible-dust-safety-program"
                >Fundamentals of a Combustible Dust Safety Program</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/electrical-classification-hazards-protection-training"
                >Electrical Classification, Hazards, and Protection
                Training</router-link
              >
            </li>
            <li>
              <router-link
                class="link"
                to="/training/advanced-explosives-site-plan-training"
                >Advanced Explosives Site Plan Training</router-link
              >
            </li>
          </ul>
          <p>
            Registration for the annual class can be found on the TCI website.
          </p>
        </Paragraph>
        <div class="flex flex-wrap mt-4">
          <div class="w-1/2 pt-4">
            <a
              href="http://www.tci-training.com"
              target="_blank"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Registration
            </a>
          </div>
          <div class="w-1/2">
            <a href="http://www.tci-training.com"
              ><img
                class="alignnone size-full wp-image-12060"
                src="@/assets/Training/tci-logo-min.jpg"
                alt="tci-logo"
                width="147"
                height="50"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "Training",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS's experienced personnel can conduct a wide range of training seminars and workshops regarding process safety management, DOT/DoD, ATF, and In-Process Classification Testing, Process Hazards Analysis, and Safety and Testing Fundamentals."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `This course combines Process Safety Management (PSM) and Process Hazards Analysis (PHA) into one course. The course provides an understanding of the practices and procedures that should be implemented to provide safe operations within the bounds of OSHAs 29 CFR 1910.119 (Process Safety Management). This course combines lecture and practical workshop exercises to allow participants to get hands-on experience conducting PHAs for explosive processes within the requirements of the PSM requirements.`,
          title:
            "Process Safety Management and Classification Testing and Process Hazard Analysis Training",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full md:w-1/2">
                <button data-link="/training/process-safety-management-psm-principles-explosive-operations" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    PSM Learn More
                </button>
            </div>
            <div class="w-full mt-2 md:mt-0 md:w-1/2 md:pl-2">
                <button data-link="/training/process-hazards-analysis-pha-training-for-team-leaders" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    PHA Learn More
                </button>
            </div>
          </div>`
        },
        {
          content1: `This course presents the testing protocol associated with classifying propellant, explosives, and pyrotechnics (PEP) for transportation, storage, and facility siting.`,
          title: "DOT / DoD, ATF, and In-Process Classification Testing",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full">
                <button data-link="/training/dot-atf-process-classification-testing" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Learn More
                </button>
            </div>
          </div>`
        },
        {
          content1: `This course instructs personnel on the recognition of explosive hazards and safe operations specifically related to cleanup and decommissioning activities. These concepts can be applied to ongoing processes and equipment or the removal of old equipment and facilities.`,
          title: "Explosives Recognition and Decontamination",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full">
                <button data-link="/training/explosives-recognition-and-decontamination" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Learn More
                </button>
            </div>
          </div>`
        },
        {
          content1: `This course outlines the 7 basic principles of explosive safety, and addresses industry standards relating to explosives manufacturing.`,
          title: "Fundamentals of Explosives Safety",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full">
                <button data-link="/training/fundamentals-of-explosives-safety" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Learn More
                </button>
            </div>
          </div>`
        },
        {
          content1: `This course focuses on testing protocol and standards for energetic materials for transportation, storage, facility siting, and in-process classification/characterization.`,
          title: "Fundamentals of Explosives Testing",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full">
                <button data-link="/training/fundamentals-of-explosives-safety" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Learn More
                </button>
            </div>
          </div>`
        },
        {
          content1: `This course discusses the contents of NFPA 652, the precautionary measures needed to prevent dust explosions, and the requirements of a Dust Hazard Analysis (DHA).`,
          title: "Fundamentals of a Combustible Dust Safety Program",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full">
                <button data-link="/training/fundamentals-of-a-combustible-dust-safety-program" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Learn More
                </button>
            </div>
          </div>`
        },
        {
          content1: `This course is intended as a trainer level course to provide those attending this course with the skills and knowledge to take this information and train other staff within their respective organizations on the electrical classification, standards, compliance, and industry practice as it relates to electrical equipment in hazardous areas.`,
          title: "Electrical Classification, Hazards, and Protection Training",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full">
                <button data-link="/training/electrical-classification-hazards-protection-training" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Learn More
                </button>
            </div>
          </div>`
        },
        {
          content1: `This course is an advanced level course that provides participants with in-depth knowledge of the critical elements of explosive site plans (ESPs) and the associated quantity-distance (QD) relationships.`,
          title: "Advanced Explosives Site Plan Training",
          content2: `
          <div class="flex flex-wrap mt-4">
            <div class="w-full">
                <button data-link="/training/advanced-explosives-site-plan-training" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Learn More
                </button>
            </div>
          </div>`
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.training {
  .link {
    color: #aa222c;
  }
}
</style>
